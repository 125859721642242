import React from 'react';
import Home from './views/Home';
import FormDiplomaticClearance from './views/FormDiplomaticClearance';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" render={() => (
              <Redirect to="/diplomatic-clearance"/>
          )}/>
          <Route exact path="/diplomatic-clearance">
            <Home />
          </Route>
          <Route path="/diplomatic-clearance.1826">
            <FormDiplomaticClearance/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
