import React from "react";
import ReactDOM from "react-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./polyfill";
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(<App />, document.getElementById("root"));
