let Endpoint;
if(process.env.REACT_APP_API_END_POINT){
    Endpoint = process.env.REACT_APP_API_END_POINT;
}else{
    Endpoint = "https://na-client-api-web-dev.azurewebsites.net/";
}

let EndpointPrefix = "api"

export const nationalities = `${Endpoint}${EndpointPrefix}/country`
export const postDiplomaticClearance = `${Endpoint}${EndpointPrefix}/diplomaticapplication`
export const boundDestinationAerodromes = `${Endpoint}${EndpointPrefix}/Aerodrome/bounddestination`