//import "bootstrap/dist/css/bootstrap.css";
import React, { Component } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";

import { Link } from "react-router-dom";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      privacy: false,
      AcceptTerms: false,
    };
    this.handleAcceptTerms = this.handleAcceptTerms.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  handleAcceptTerms() {
    this.setState({
      AcceptTerms: !this.state.AcceptTerms,
    });
  }

  toggle() {
    this.setState({
      privacy: !this.state.privacy,
    });
  }

  render() {
    return (
      <div>
        <Header />
        <div className="contentContainer contentContainer--Home">
          <div className="contentHome">
            <h4>
              <b>
                REQUESTS FOR DIPLOMATIC CLEARANCE FOR FOREIGN CIVILIAN
                REGISTERED STATE AIRCRAFT TO OPERATE IN THE AIRSPACE OF DENMARK,
                GREENLAND OR FAROE ISLANDS
              </b>
            </h4>
            <b style={{ margin: "40px 0 15px" }}>
              A. General Clearance Requirements
            </b>
            <p>
              1. Foreign governments seeking diplomatic clearance for civilian
              registered state aircraft to transit or land within the airspace
              of Denmark, Greenland or Faroe Islands must obtain a Diplomatic
              Clearance Number (DCN) issued in advance by Naviair according to
              act no. 1149 13 October 2017 section 156 and “AIP DENMARK GEN 1.2
              Entry, Transit and Departure of Aircraft, Section 7”.
            </p>
            <p>
              2. A DCN authorizes the aircraft to transit or land in the
              territory of Denmark, Greenland or Faroe Islands in accordance
              with the approved itinerary.
            </p>
            <p>
              3. To obtain a DCN, foreign governments must apply to Naviair
              through diplomatic channels (an embassy or equivalent) via the
              web-based Diplomatic Clearance Application System. Please follow
              the link below to the application form.
            </p>
            <p>
              4. Once Naviair verifies that all necessary data has been provided
              and that Naviair has made sure that relevant authorities has been
              consulted and accepted the application, a diplomatic clearance
              with a unique Diplomatic Clearance Number (DCN) will be granted
              and issued.
            </p>
            <p>
              5. Aircraft landing in Copenhagen Airport, Kastrup, can be
              exempted from the en route-fees. A separate form must be filled in
              and send by email to the Route Charges Office at Naviair. The form
              will, when relevant, be submitted to the applicant together with
              the issued permission.
            </p>
            <p>
              6. It is the requesting foreign government’s responsibility to
              meet all other applicable Danish requirements.
            </p>
            <b style={{ margin: "40px 0 15px" }}>
              B. Aircraft clearance lead time and validation
            </b>
            <p>
              1. Each foreign government (foreign embassy) must submit
              diplomatic clearance requests via the Diplomatic Clearance
              Application System no later than 10 days before the estimated time
              for entry of the territory of Denmark, Greenland or Faroe Islands.
              Saturdays, Sundays and Danish public holidays shall not be counted
              in this time limit.
            </p>
            <p>
              2. Complying with the 10 days advance notification procedure is
              critical to ensure necessary information is provided with adequate
              lead-time to the various Danish authorities overseeing aircraft
              going into and within Danish, Greenlandic or Faroe Islands
              airspace.
            </p>
            <p>
              3. Exceptions to the 10 days advance notification requirement may
              be granted for only a limited number of circumstances and only if
              the application is accompanied by an explanation why the request
              does not meet the 10 days advance timeline.
            </p>
            <p>
              4. For short-notice situations that qualify for an exception to
              standard procedure:
            </p>
            <p style={{ marginLeft: "15px" }}>
              - Submit a complete application via the Diplomatic Clearance
              Application System
            </p>
            <p style={{ marginLeft: "15px" }}>
              - In addition to the application send an email to{" "}
              <a
                href="mailto: diplomatic@naviair.dk"
                style={{ display: "initial", textDecoration: "underline" }}
              >
                diplomatic@naviair.dk
              </a>{" "}
              to highlight and to justify the pending short-notice request.
            </p>
            <p style={{ marginLeft: "15px" }}>
              - If a short-notice change has to be made to an existing
              application then send the additional information via email to{" "}
              <a
                href="mailto: diplomatic@naviair.dk"
                style={{ display: "initial", textDecoration: "underline" }}
              >
                diplomatic@naviair.dk
              </a>
              .
            </p>
            <p>
              5. In a very limited number of circumstances, Naviair will take
              action outside office hours. If action is needed outside office
              hours, please call telephone number +45 3248 1933 to alert Watch
              Supervisor of the request for support.
            </p>
            <p>
              6. Validity of Diplomatic Clearances: DCNs are valid for a period
              of +/- 72 hours from the expected time of entry/exit from the
              territory of Denmark, Greenland or Faroe Islands.
            </p>
            <p>
              7. Any arrival or departure beyond the timeframes established
              herein requires the foreign government (the foreign embassy) to
              immediately submit a new application and send an email to
              diplomatic@naviair.dk describing the changes.
            </p>
            <p>
              8. It is the responsibility of the foreign government (the foreign
              embassy) to coordinate and confirm with the appropriate airport
              points of contact all arrival and departure schedules and changes.
            </p>
            <p>
              9. The clearance is valid only according to the itinerary as
              approved by Naviair and specified in the application.
            </p>
            <b style={{ margin: "40px 0 15px" }}>
              C. Diplomatic clearance for military registered aircraft
            </b>
            <p>
              1. If exemption has not been granted by special arrangement, entry
              of the territory of Denmark, Greenland or the Faroe Islands by
              non-NATO military aircraft is subject to prior diplomatic
              clearance. Application for flights with military registered
              aircraft must be send through diplomatic channels to Defence
              Command Denmark to email{" "}
              <a
                href="mailto: flight.clearance.dnk@mil.dk"
                style={{ display: "initial", textDecoration: "underline" }}
              >
                {" "}
                flight.clearance.dnk@mil.dk{" "}
              </a>
              .
            </p>

            <b style={{ margin: "40px 0 15px" }}>
              D. Procedures regarding transportation of dangerous goods
            </b>
            <p>
              1. All transportation of dangerous goods must comply with ICAO-TI
              Technical Instructions for the safe Transport of Dangerous Goods
              by Air – latest edition, and Danish Executive Order No.
              <a
                href="https://www.retsinformation.dk/eli/lta/2008/763"
                target="blank"
                style={{ display: "initial", textDecoration: "underline" }}
              >
                {" "}
                763 of 11 July 2008.
              </a>
            </p>
            <p>
              2. Transport of weapons, explosives, war materiel and war
              ammunition, etc. with civil aircraft must comply with the rules of
              <a
                href="https://www.retsinformation.dk/eli/lta/1995/527"
                target="blank"
                style={{ display: "initial", textDecoration: "underline" }}
              >
                {" "}
                Danish Executive Order No. 527 of 23 June 1995
              </a>
              . Clearance for weapons, explosives, war materiel and war
              ammunition, etc. is issued by The
              <a
                href="https://www.trafikstyrelsen.dk/arbejdsomraader/luftfart/farligt-gods/transport-af-farligt-gods#heading4"
                target="blank"
                style={{ display: "initial", textDecoration: "underline" }}
              >
                {" "}
                Danish Civil Aviation Authority
              </a>
              . Applications must be sent directly to The Danish Civil Aviation Authority or by using the dedicated
              blocks in the application form below.
            </p>
            <p>
              3. Please note that transport of dangerous goods in Class 1.4S
              does not require a permission.
            </p>
            <p>
              4. Clearance for forbidden dangerous goods will be issued
              according to the{" "}
              <a
                href="https://www.retsinformation.dk/eli/lta/1995/527"
                target="blank"
                style={{ display: "initial", textDecoration: "underline" }}
              >
                Danish Executive Order No. 527 of 23 June 1995
              </a>{" "}
              . Applications must be sent directly to The Danish Civil Aviation Authority or by using the dedicated
              blocks in the application form. See example below.
            </p>
            <p>
              EXAMPLE:
              <br />
              Numbers, UN Number, Proper shipping name, Class or Div. Class, Net
              weight, PI
            </p>
            <table>
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>UN Number</th>
                  <th>Proper shipping name</th>
                  <th>Class</th>
                  <th>Net weight</th>
                  <th>PI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1 Box </td>
                  <td>UN 181</td>
                  <td>Rockets with bursting charge </td>
                  <td>1.1 E</td>
                  <td>0.5 Kgs</td>
                  <td>130</td>
                </tr>
              </tbody>
            </table>

            <b style={{ margin: "40px 0 15px" }}>
              E. Procedures to submit diplomatic clearance requests
            </b>
            <p>
              1. Please fill in the information asked for in the application
              form below.
            </p>
            <p>
              2. Naviair coordinates applications with the Danish Transport,
              Construction and Housing Authority and the Danish Ministry of
              Foreign Affairs before issuing a diplomatic clearance to an
              aircraft.
            </p>
            <p>
              3. Naviair will inform relevant authorities in Denmark, Greenland
              or Faroe Islands of permissions issued for state flights.
            </p>

            <b style={{ margin: "40px 0 15px" }}>F. Points of contact</b>
            <p>
              1. For questions about diplomatic aircraft clearances regarding
              civilian registered aircraft, the points of contact at Naviair
              are:
            </p>
            <p>Weekdays / Working days / From 9 to 16:</p>
            <p>Helle Kogsbøll, phone +45 3247 7912 or</p>
            <p>Charlotte Perch Hellinghus, phone +45 3247 8912</p>
            <p>Outside the above-mentioned period:</p>
            <p>Watch Supervisor, phone +45 3248 1933.</p>

            <hr />

            <div>
              <b style={{ margin: "40px 0 15px" }}>
                Use of Naviair's website for applications for state flights.
              </b>

              <p>
                The website is made available for the purpose of submitting
                information for processing applications for state flights in
                Danish, Greenlandic and Faroese airspace. Here in our privacy
                policy / disclaimer you can read how we handle the personal data
                we hold. By using this website, you accept - and the persons you
                provide the information on behalf of - the terms of this privacy
                policy / disclaimer. It is therefore your responsibility to
                ensure that these persons agree to these terms.
              </p>

              <p>
                <strong>1. About Naviair</strong>
              </p>

              <p>
                Naviair provides safe and efficient traffic management for
                aviation. Naviair also processes applications for state flight
                performed by civil aircraft. Naviair was established in 2010 as
                an independent public undertaking under Act No. 529 of 26 May
                2010.
              </p>

              <p>
                <strong>2. Confidentiality</strong>
              </p>

              <p>
                The privacy policy covers all personal data that the applicant
                provides and Naviair collects for the purpose of processing
                applications for state flights.
              </p>

              <p>
                All information provided is treated as confidential information
                and is only available to the trusted staff of Naviair and the
                relevant authorities who are evaluating the application. Any
                exception to this can and will only be done with prior
                acceptance. If we receive a request for access to the case in
                which your personal data is included, we will usually disclose
                the information unless the information is regarded confidential.
              </p>

              <p>
                <strong>3. Legal basis for processing personal data</strong>
              </p>
              <p>
                The processing of your personal data is based on Article 6 (1) a
                and e of the Data Protection Regulation.
              </p>

              <p>
                <strong>4. Storage of (personal) information</strong>
              </p>

              <p>
                As part of the public administration, Naviair must comply with
                the general rules of administrative law and is therefore obliged
                to archive under the Public Law. This means that the information
                sent to Naviair is updated in our archives when a case is
                created.
              </p>

              <p>
                We keep your personal information in our records. The
                information is continuously transmitted to the National Archives
                according to the rules of the Archives Act and the provisions of
                the State Archives.
              </p>

              <p>
                Section 15 (1) of the Public Act. means that documents received
                or sent by a managing authority as part of administrative
                proceedings in connection with its activities must be
                journalized to the extent that the document is relevant to a
                case or the proceedings in general.
              </p>

              <p>
                <strong>5. Security</strong>
              </p>
              <p>
                At Naviair, we have high standards of security, including the
                protection of your personal information. Therefore, we have
                several internal procedures and policies that ensure that we
                live up to our high safety standards.
              </p>

              <p>
                <strong>6. Cookies</strong>
              </p>

              <p>This website does not use cookies.</p>

              <p>
                <strong>7. Rights</strong>
              </p>

              <p>
                If you have submitted an application for state flight to Naviair
                and this contains personal data, you have the right to gain
                access to the personal data we have collected about you, You
                also have the right to object to our collection and against the
                further processing of your personal information. You also have
                the right to have your personal information corrected if needed.
                In special cases, you have the right to have information deleted
                before the time of our regular general deletion.
              </p>

              <p>
                If the processing of your personal data is based on your
                consent, you have the right to revoke your consent at any time.
                Your withdrawal will not affect the legality of the treatment
                made prior to your withdrawal of your consent.
              </p>
              <p>
                If you would like to exercise your rights, please contact us.
              </p>

              <p>
                <strong>8. Do you want to change your information?</strong>
              </p>

              <p>
                You may write to Naviair's data protection adviser{" "}
                <a
                  href="mailto: dpo@naviair.dk"
                  style={{ display: "initial", textDecoration: "underline" }}
                >
                  (dpo@naviair.dk)
                </a>{" "}
                if you want information regarding the information we have
                collected about you or if you want to change or delete the
                personal information, or if you have questions about the
                guidelines in our Privacy Policy.{" "}
              </p>
              <p>
                If you have any questions about the case itself, please contact
                Naviair's legal department "Legal Affairs".
              </p>

              <p>
                <strong>9. Disclaimer</strong>
              </p>
              <p>
                Naviair is not responsible for any direct or indirect loss
                incurred as a result of accessing or using the content of this
                website.
              </p>

              <p>
                <strong>10. Complaints</strong>
              </p>

              <p>
                If you do not believe that our processing of your personal data
                is in accordance with applicable law, you can always complain to
                the Data Protection Authority. However, in the first instance,
                we encourage you to contact us so that we may have the
                opportunity to investigate the matter first.
              </p>

              <p>
                This privacy policy / disclaimer was last modified on May 11,
                2020.
              </p>
            </div>

            <hr />
            <div className="formGroupInput formGroupInput--checkBox">
              <input
                type="checkbox"
                id="AcceptTerms"
                name="AcceptTerms"
                checked={this.state.AcceptTerms}
                onChange={this.handleAcceptTerms}
                className="css-checkbox"
              />
              <label
                htmlFor="AcceptTerms"
                className="css-label"
                style={{ cursor: "pointer" }}
              >
                I am applying on behalf of an embassy
              </label>
            </div>
            <Link
              className={
                this.state.AcceptTerms ? "inlinelink active" : "inlinelink"
              }
              to="/diplomatic-clearance.1826"
            >
              &gt; Diplomatic clearance (for one flight)
            </Link>

            <a
              href="/diplomatic-clearance"
              onClick={() => window.print()}
              className="printIcon"
            >
              printIcon
            </a>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
