import React from 'react'

export default props => (
    <div>
        <p>Dear Sir or Madam</p>
        

        <p>Thank you for your request, regarding overflying permits for Danish territory.</p>

        <p>Your request will be processed as soon as possible, within office hours Monday
        to Friday 0800-1500.</p>
        
        <p><b>Is the request of a very urgent matter, and sent outside
        office hours,</b> please contact Watch Supervisor ATCC København on telephone +45
        3248 1933.</p>
    </div>
)