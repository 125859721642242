import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div className="footer">       
                <div className="footerInfo">
                    <span>Naviair Allé 1 | DK 2770 Kastrup | Telefon: 3247 8000 | Fax: 3247 8800 |
                        <a href="mailto:presse@naviair.dk" className="inlinelink" style={{opacity: '1'}}> presse@naviair.dk</a> | CVR 26059763
                    </span>
                </div>
            </div>
        )
    }
}

export default Footer
