import React from 'react'

export default props => (
    <div className="header">
        <div className="dark-bg"></div>
        <div className="contentHeader">
            <div className="contentHeaderContain">
                <div className="medium-bg"></div>
                <div>
                    <div className="naviair-logo"></div>
                </div>
            </div>
        </div>
        <div className="sky-bg"></div>
    </div> 
)