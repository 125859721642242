import React from 'react'
import { Link } from "react-router-dom";

export default props => (
    <div className="alert">
        <div className="alertContent alertContent--error">
            <Link className="closeAlert" to="/diplomatic-clearance">x</Link>
            {props.errorMessage}
        </div>
    </div>
)