import React, { Component } from "react";
import DatePicker from "react-datepicker";
import Sucess from "../views/Sucess";
import * as options from "./Helpers";
import "whatwg-fetch";
import Loading from "./Loading";
import ErrorAlert from "./ErrorAlert";
import PropTypes from "prop-types";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { Label } from "reactstrap";
import moment from "moment-timezone";

export class ValidationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      vlExistRN: false,
      vlExistRCS: false,

      countryId: "",
      Nationality: "",
      AircraftStatus: "",
      BoundDestinationAerodromeItems: [],
      RegistrationNumberArray: [],
      RegistrationNumberFirstValeu: "",
      RegistrationNumberSecondValeu: "",
      AllowNumberCaractersRegistrationNumber: 6,
      RegistrationNumber: "",
      RegistrationNumberArrayToSubmit: [],
      RegistrationNumberValeu: "",
      prefixRNRequired: false,
      sufixRNRequired: false,

      allDate: {},

      afterLoad: false,
      DepartureTimeLessThanToday: false,
      estimatedTimeOfArrivalLessThanToday: false,
      ETDRequired: false,
      ETDInThePast: false,
      ETARequired: false,
      PointOfEntryDateRequired: false,

      RadioCallSignArray: [],
      RadioCallSignFirstValeu: "",
      RadioCallSignSecondValeu: "",
      AllowNumberCaractersRadioCallSign: 7,
      RadioCallSign: "",
      RadioCallSignArrayToSubmit: [],
      RadioCallSignValeu: "",
      requiredRCS: false,
      prefixRCSRequired: false,
      prefixRCSRequiredTxt: false,
      sufixRCSRequired: false,
      sufixRCSRequiredTxt: false,
      RCSemptyArray: false,

      AircraftType: "",
      DepartureAerodromeICAO: "",
      DepartureAerodromeName: "",
      PointOfEntry: "",
      PointOfEntryDate: "",
      Comments: "",
      estimatedTimeOfArrival: "",
      FlightRouteWithinDanish: "",
      DestinationAerodromeICAO: "",
      DestinationAerodromeName: "",
      ExpectedTimeOfDeparture: "",
      BoundestimatedTimeOfArrival: "",
      BoundFlightRouteWithinDanish: "",
      BoundDestinationAerodrome: "",
      BoundDepartureDate: "",
      ExitDate: "",
      PointOfExit: "",
      purpose: "",
      Summary: "",
      HasDangerousGoods: false,
      HasDangerousGoodsAuthorization: false,
      DangerousGoods: "",
      hasClassOneDangerousGoods: false,
      HasWeaponsAuthorizathion: false,
      HasWeapons: false,
      Weapons: "",
      Remarks: "",
      EntersDenmark: false,
      EntersFaroeIslands: false,
      EntersGreenland: false,
      FlightOperator: "",
      ApplicantName: "",
      ApplicantEmail: "",
      applicantTelephoneNumber: "",
      Embassy: "",
      embassyAddress: "",
      Urgent: false,
      SpecialGoods: false,
      ETD: "",
      ETA: "",
      Status: 0,

      quantity: "",
      unNumber: "",
      properShippingName: "",
      class: "",
      netWeight: "",
      pi: "",
      DGArrayToSubmit: [],
      showErrorsDG: false,

      varifyBoundestimatedTimeOfArrival: false,

      Cookies: true,
      sucessForm: false,
      isLoaded: false,
      ErrorMessages: [],
      showTableRN: false,
      showTableRCS: false,
      showTableDG: false,
      DGemptyArray: false,
      LoadingResponse: false,
      today: moment(new Date()).toDate(),
      // today: moment.utc().toLocaleString(),
      endOfDay: moment(new Date()).endOf("day").toDate(),
      startOfDay: moment(new Date()).startOf("day").toDate(),
      ETDmaxTime: moment(new Date()).endOf("day").toDate(),
      PODmaxTime: moment(new Date()).add({ minutes: 1 }).toDate(),
      BDDmaxTime: moment(new Date()).endOf("day").toDate(),
      BADmaxTime: moment(new Date()).endOf("day").toDate(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNationality = this.handleNationality.bind(this);
    this.handleRegistrationNumber = this.handleRegistrationNumber.bind(this);
    this.handleRadioCallSign = this.handleRadioCallSign.bind(this);

    this.handleCheckboxEntersDenmark = this.handleCheckboxEntersDenmark.bind(
      this
    );
    this.handleCheckboxEntersFaroeIslands = this.handleCheckboxEntersFaroeIslands.bind(
      this
    );
    this.handleCheckboxEntersGreenland = this.handleCheckboxEntersGreenland.bind(
      this
    );
    this.verifyAirspace = this.verifyAirspace.bind(this);

    this.handleCheckboxSpecialGoods = this.handleCheckboxSpecialGoods.bind(
      this
    );

    this.handleDangerousGoods = this.handleDangerousGoods.bind(this);
    this.handleWeapons = this.handleWeapons.bind(this);
    this.hasClassOneDangerousGoods = this.hasClassOneDangerousGoods.bind(this);
    this.HasDangerousGoodsAuthorization = this.HasDangerousGoodsAuthorization.bind(
      this
    );
    this.HasWeaponsAuthorizathion = this.HasWeaponsAuthorizathion.bind(this);

    this.changeAllDates = this.changeAllDates.bind(this);

    this.ETDLessThanETA = this.ETDLessThanETA.bind(this);
    this.POFGreatherThanETD = this.POFGreatherThanETD.bind(this);
    this.POFLessThanETA = this.POFLessThanETA.bind(this);
    this.ETALesThanBDD = this.ETALesThanBDD.bind(this);
    this.BDDGreatherBAD = this.BDDGreatherBAD.bind(this);
    this.ULessThanX = this.ULessThanX.bind(this);
    this.XGreatherThanS = this.XGreatherThanS.bind(this);

    this.addRegistrationNumber = this.addRegistrationNumber.bind(this);
    this.addRadioCallSign = this.addRadioCallSign.bind(this);

    this.removeItemRCS = this.removeItemRCS.bind(this);
    this.removeItemRN = this.removeItemRN.bind(this);

    this.addDangerousGood = this.addDangerousGood.bind(this);
    this.removeItemDGItem = this.removeItemDGItem.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyRCSarray = this.verifyRCSarray.bind(this);
  }

  //FUNCTIONS TO HANDLE NATIONALITY, AIRCRAFT AND RADIO CALL SIGNS
  handleNationality(e) {
    const value = e.target.value;

    this.setState({
      Nationality: value,
      RegistrationNumberArrayToSubmit: [],
      RadioCallSignArrayToSubmit: [],
      RegistrationNumberRequired: false,
      RCSemptyArray: false,
      showTableRCS: false,
      showTableRN: false,
    });

    if (value !== null) {
      const countrySelected = this.state.nationalitiesItems.find(
        (nationality) => nationality.name === value
      );
      let countryId = countrySelected.countryId;

      this.setState({
        countryId: countryId,
      });

      if (countrySelected.aircraftRegistrations !== undefined) {
        const registrationAircraft = countrySelected.aircraftRegistrations;
        this.setState({
          RegistrationNumberArray: registrationAircraft,
          RegistrationNumberFirstValeu: "",
          RegistrationNumberSecondValeu: "",
          vlExistRN: false,
        });
      }
      if (countrySelected.aircraftCallSigns !== undefined) {
        const radioCallSign = countrySelected.aircraftCallSigns;
        this.setState({
          RadioCallSignArray: radioCallSign,
          RadioCallSignFirstValeu: "",
          RadioCallSignSecondValeu: "",
          vlExistRN: false,
        });
      }
    }
  }

  handleRegistrationNumber = (e) => {
    let RegistrationNumberSelected = e.target.value;
    if (RegistrationNumberSelected === null) {
      this.setState({
        AllowNumberCaractersRegistrationNumber: 10,
        RegistrationNumberRequired: false,
        prefixRNRequired: false,
        sufixRNRequired: false,
        prefixRNRequiredTxt: false,
        sufixRNRequiredTxt: false,
        vlExistRN: false,
      });
    } else {
      const numberOfCarachters = RegistrationNumberSelected.length;
      const restOfCharacters = 10 - numberOfCarachters;

      this.setState({
        AllowNumberCaractersRegistrationNumber: restOfCharacters,
        RegistrationNumberFirstValeu: RegistrationNumberSelected,
        RegistrationNumberSecondValeu: "",
        RegistrationNumberRequired: false,
        prefixRNRequired: false,
        sufixRNRequired: false,
        prefixRNRequiredTxt: false,
        sufixRNRequiredTxt: false,
      });
    }
  };

  handleRadioCallSign = (e) => {
    let RadioCallSignSelected = e.target.value;
    if (RadioCallSignSelected === null) {
      this.setState({
        AllowNumberCaractersRadioCallSign: 7,
        RadioCallSignRequired: false,
        sufixRCSRequired: false,
        sufixRCSRequiredTxt: false,
        prefixRCSRequired: false,
        prefixRCSRequiredTxt: false,
        RadioCallSignSecondValeu: "",
        vlExistRCS: false,
      });
    } else {
      const numberOfCarachters =
        RadioCallSignSelected.toUpperCase() === "OTHER"
          ? 0
          : RadioCallSignSelected.length;
      const restOfCharacters = 7 - numberOfCarachters;

      this.setState({
        AllowNumberCaractersRadioCallSign: restOfCharacters,
        RadioCallSignFirstValeu: RadioCallSignSelected,
        RadioCallSignSecondValeu: "",
        RadioCallSignRequired: false,
        sufixRCSRequired: false,
        sufixRCSRequiredTxt: false,
        prefixRCSRequired: false,
        prefixRCSRequiredTxt: false,
        vlExistRCS: false,
      });
    }
  };

  addRegistrationNumber(e) {
    e.preventDefault();
    let vlRN =
      this.state.RegistrationNumberFirstValeu +
      this.state.RegistrationNumberSecondValeu;

    if (vlRN === "") {
      this.setState({
        prefixRNRequired: true,
        sufixRNRequired: true,
      });
    } else {
      this.setState({
        prefixRNRequired: false,
        sufixRNRequired: false,
      });
    }

    if (this.state.RegistrationNumberFirstValeu !== "") {
      if (this.state.RegistrationNumberSecondValeu === "") {
        this.setState({
          sufixRNRequired: true,
          verifyRNArray: false,
          sufixRNRequiredTxt: true,
        });
      }
    }

    if (this.state.RegistrationNumberSecondValeu !== "") {
      if (this.state.RegistrationNumberFirstValeu === "") {
        this.setState({
          prefixRNRequired: true,
          verifyRNArray: false,
        });
      }
    }

    if (this.state.RegistrationNumberArrayToSubmit.length >= 1) {
      let findValueExist = this.state.RegistrationNumberArrayToSubmit.find(
        (i) => i === vlRN
      );

      if (findValueExist !== undefined) {
        this.setState({ vlExistRN: true });
      } else {
        this.setState({ vlExistRN: false });
        if (this.state.RegistrationNumberSecondValeu !== "") {
          if (this.state.RegistrationNumberFirstValeu !== "") {
            this.setState({
              showTableRN: true,
              RegistrationNumberValeu: vlRN,
              RegistrationNumberFirstValeu: "",
              RegistrationNumberSecondValeu: "",
              prefixRNRequired: false,
              sufixRNRequired: false,
              verifyRNArray: false,
            });
            this.state.RegistrationNumberArrayToSubmit.push(vlRN);
          }
        }
      }
    } else {
      if (this.state.RegistrationNumberSecondValeu !== "") {
        if (this.state.RegistrationNumberFirstValeu !== "") {
          this.setState({
            showTableRN: true,
            RegistrationNumberValeu: vlRN,
            RegistrationNumberFirstValeu: "",
            RegistrationNumberSecondValeu: "",
            prefixRNRequired: false,
            sufixRNRequired: false,
            verifyRNArray: false,
          });
          this.state.RegistrationNumberArrayToSubmit.push(vlRN);
        }
      }
    }
  }

  removeItemRN(i) {
    let arrayRN = this.state.RegistrationNumberArrayToSubmit;
    arrayRN.splice(i, 1);
    let newArray = this.state.RegistrationNumberArrayToSubmit;
    this.setState({
      RegistrationNumberArrayToSubmit: newArray,
    });

    if (this.state.RegistrationNumberArrayToSubmit.length === 0) {
      this.setState({
        showTableRN: false,
      });
    }
  }

  addRadioCallSign(e) {
    e.preventDefault();
    let vlRCD;
    if (this.state.RadioCallSignFirstValeu.toUpperCase() === "OTHER") {
      vlRCD = this.state.RadioCallSignSecondValeu;
    } else {
      vlRCD =
        this.state.RadioCallSignFirstValeu +
        this.state.RadioCallSignSecondValeu;
    }

    if (vlRCD === "") {
      this.setState({
        prefixRCSRequired: this.state.RadioCallSignFirstValeu === "",
        sufixRCSRequired: true,
      });
    }

    if (this.state.RadioCallSignFirstValeu !== "") {
      if (this.state.RadioCallSignSecondValeu === "") {
        this.setState({
          sufixRCSRequired: true,
          sufixRCSRequiredTxt: true,
          RCSemptyArray: false,
        });
      }
    }

    if (this.state.RadioCallSignSecondValeu !== "") {
      this.setState({
        prefixRCSRequired: false,
        prefixRCSRequiredTxt: false,
        RCSemptyArray: false,
      });

      if (this.state.RadioCallSignFirstValeu === "") {
        this.setState({
          prefixRCSRequired: true,
          prefixRCSRequiredTxt: false,
          RCSemptyArray: false,
        });
      }
    }

    if (this.state.RadioCallSignArrayToSubmit.length >= 1) {
      let findValueExist = this.state.RadioCallSignArrayToSubmit.find(
        (i) => i === vlRCD
      );
      if (findValueExist !== undefined) {
        this.setState({ vlExistRCS: true });
      } else {
        this.setState({ vlExistRCS: false });
        if (this.state.RadioCallSignSecondValeu !== "") {
          if (this.state.RadioCallSignFirstValeu !== "") {
            this.setState({
              showTableRCS: true,
              RadioCallSignValeu: vlRCD,
              RadioCallSignFirstValeu: "",
              RadioCallSignSecondValeu: "",
              prefixRCSRequired: false,
              prefixRCSRequiredTxt: false,
              sufixRCSRequired: false,
              sufixRCSRequiredTxt: false,
              requiredRCS: false,
              RCSemptyArray: false,
            });
            this.state.RadioCallSignArrayToSubmit.push(vlRCD);
          }
        }
      }
    } else {
      if (this.state.RadioCallSignSecondValeu !== "") {
        if (this.state.RadioCallSignFirstValeu !== "") {
          this.setState({
            showTableRCS: true,
            RadioCallSignValeu: vlRCD,
            RadioCallSignFirstValeu: "",
            RadioCallSignSecondValeu: "",
            prefixRCSRequired: false,
            prefixRCSRequiredTxt: false,
            sufixRCSRequired: false,
            sufixRCSRequiredTxt: false,
            requiredRCS: false,
            RCSemptyArray: false,
          });
          this.state.RadioCallSignArrayToSubmit.push(vlRCD);
        }
      }
    }
  }

  removeItemRCS(i) {
    let arrayRCS = this.state.RadioCallSignArrayToSubmit;
    arrayRCS.splice(i, 1);
    let newArray = this.state.RadioCallSignArrayToSubmit;
    this.setState({
      RadioCallSignArrayToSubmit: newArray,
    });

    if (this.state.RadioCallSignArrayToSubmit.length === 0) {
      this.setState({
        showTableRCS: false,
      });
    }
  }

  removeItemDGItem(i) {
    let arrayDG = this.state.DGArrayToSubmit;
    arrayDG.splice(i, 1);
    let newArray = this.state.DGArrayToSubmit;
    this.setState({
      DGArrayToSubmit: newArray,
    });

    if (newArray.length === 0) {
      this.setState({
        showTableDG: false,
      });
    }
  }

  verifyRCSarray() {
    if (this.state.RadioCallSignArrayToSubmit.length === 0) {
      let prefix = this.state.RadioCallSignFirstValeu;
      let subfix = this.state.RadioCallSignSecondValeu;
      let value = prefix + subfix;

      if (value === "") {
        this.setState({
          requiredRCS: true,
        });
      } else {
        this.setState({
          requiredRCS: false,
        });
      }
    }
  }

  //FUNCTIONS TO HANDLE BOUNDS
  handleBoundDestinationAerodrome = (e) => {
    const value = e.target.value;

    this.setState({
      BoundDestinationAerodrome: value,
    });

    if (value === "") {
      this.setState({
        BoundDestinationAerodrome: "",
        ifAircraftIsBound: false,
        BoundDepartureDate: "",
        BoundFlightRouteWithinDanish: "",
        BoundestimatedTimeOfArrival: "",
      });
    } else {
      this.setState({
        BoundDestinationAerodrome: value,
        verifyBoundDestinationAerodrome: false,
        ifAircraftIsBound: true,
      });
    }
  };

  verifyBounds = () => {
    if (this.state.BoundDestinationAerodrome !== "") {
      this.setState({
        verifyBoundDepartureDate: true,
      });

      if (
        this.state.BoundDepartureDate === "" ||
        this.state.BoundDepartureDate === null
      ) {
        this.setState({
          verifyBoundDepartureDate: true,
        });
      } else {
        this.setState({
          verifyBoundDepartureDate: false,
        });
      }
    }

    if (this.state.BoundDepartureDate !== "") {
      if (this.state.BoundFlightRouteWithinDanish === "") {
        this.setState({
          verifyBoundFlightRouteWithinDanish: true,
        });
      } else {
        this.setState({
          verifyBoundDepartureDate: false,
        });
      }

      if (this.state.BoundDestinationAerodrome === "") {
        this.setState({
          verifyBoundDestinationAerodrome: true,
        });
      } else {
        this.setState({
          verifyBoundDepartureDate: false,
        });
      }

      if (
        this.state.BoundestimatedTimeOfArrival === "" ||
        this.state.BoundestimatedTimeOfArrival === null
      ) {
        this.setState({
          varifyBoundestimatedTimeOfArrival: true,
        });
      } else {
        this.setState({
          verifyBoundDepartureDate: false,
        });
      }
    }

    if (this.state.BoundFlightRouteWithinDanish !== "") {
      if (
        this.state.BoundDepartureDate === "" ||
        this.state.BoundDepartureDate === null
      ) {
        this.setState({
          verifyBoundDepartureDate: true,
        });
      } else {
        this.setState({
          verifyBoundFlightRouteWithinDanish: false,
        });
      }
      if (
        this.state.BoundDestinationAerodrome === "" ||
        this.state.BoundDestinationAerodrome === null
      ) {
        this.setState({
          verifyBoundDestinationAerodrome: true,
        });
      } else {
        this.setState({
          verifyBoundFlightRouteWithinDanish: false,
        });
      }
      if (
        this.state.BoundestimatedTimeOfArrival === "" ||
        this.state.BoundestimatedTimeOfArrival === null
      ) {
        this.setState({
          varifyBoundestimatedTimeOfArrival: true,
        });
      } else {
        this.setState({
          verifyBoundFlightRouteWithinDanish: false,
        });
      }
    }

    if (this.state.BoundDestinationAerodrome !== "") {
      if (
        this.state.BoundDepartureDate === "" ||
        this.state.BoundDepartureDate === null
      ) {
        this.setState({
          verifyBoundDepartureDate: true,
        });
      } else {
        this.setState({
          verifyBoundDestinationAerodrome: false,
        });
      }
      if (this.state.BoundFlightRouteWithinDanish === "") {
        this.setState({
          verifyBoundFlightRouteWithinDanish: true,
        });
      } else {
        this.setState({
          verifyBoundDestinationAerodrome: false,
        });
      }
      if (
        this.state.BoundestimatedTimeOfArrival === "" ||
        this.state.BoundestimatedTimeOfArrival === null
      ) {
        this.setState({
          varifyBoundestimatedTimeOfArrival: true,
        });
      } else {
        this.setState({
          verifyBoundDestinationAerodrome: false,
        });
      }
    }

    if (this.state.BoundestimatedTimeOfArrival !== "") {
      if (
        this.state.BoundDepartureDate === "" ||
        this.state.BoundDepartureDate === null
      ) {
        this.setState({
          verifyBoundDepartureDate: true,
        });
      } else {
        this.setState({
          varifyBoundestimatedTimeOfArrival: false,
        });
      }
      if (this.state.BoundFlightRouteWithinDanish === "") {
        this.setState({
          verifyBoundFlightRouteWithinDanish: true,
        });
      } else {
        this.setState({
          varifyBoundestimatedTimeOfArrival: false,
        });
      }
      if (
        this.state.BoundDestinationAerodrome === "" ||
        this.state.BoundDestinationAerodrome === null
      ) {
        this.setState({
          verifyBoundDestinationAerodrome: true,
        });
      } else {
        this.setState({
          varifyBoundestimatedTimeOfArrival: false,
        });
      }
    }
  };

  verifyBoundDepartureDateEmpty = () => {
    if (this.state.BoundDepartureDate === "") {
      this.setState({
        verifyBoundDepartureDate: true,
      });
    }

    if (this.state.BoundDepartureDate === null) {
      this.setState({
        verifyBoundDepartureDate: true,
      });
    }
  };

  verifyBoundestimatedTimeOfArrival = () => {
    if (this.state.BoundestimatedTimeOfArrival === "") {
      this.setState({
        varifyBoundestimatedTimeOfArrival: true,
      });
    }

    if (this.state.BoundestimatedTimeOfArrival === null) {
      this.setState({
        varifyBoundestimatedTimeOfArrival: true,
      });
    }
  };

  // START: FUNCTIONS TO APPLY DATES
  changeAllDates = (dateStr, date) => {
    if (date === null) {
      date = "";
    }

    if (dateStr === "ETD") {
      this.setState({
        ETD: date,
        ETDRequired: false,
        ETDInThePast: false,
      });
      let etdFormat = moment(date).utc(true).format();
      let nowFormat = moment(new Date()).utc().format();
      let etd = moment.utc(etdFormat);
      let now = moment.utc(nowFormat);
      if (etd <= now) {
        this.setState({ ETDInThePast: true });
      }

      if (this.state.PointOfEntryDate !== "") {
        if (date >= this.state.PointOfEntryDate) {
          this.setState({ ETDyPOERule: true });
        } else {
          this.setState({ ETDyPOERule: false, POEyETDRule: false });
        }
      }

      if (this.state.ETA !== "") {
        if (date >= this.state.ETA) {
          this.setState({ ETDyETARule: true });
        } else {
          this.setState({ ETDyETARule: false, ETAyETDRule: false });
        }
      }
    }

    if (dateStr === "PoE") {
      this.setState({
        PointOfEntryDate: date,
        PointOfEntryDateRequired: false,
      });

      // if (date <= this.state.today) {
      //   this.setState({ PointOfEntryDateRequired: true });
      // }

      if (this.state.ETA !== "") {
        if (date >= this.state.ETA) {
          this.setState({ POEyETARule: true });
        } else {
          this.setState({ POEyETARule: false, ETAyPOERule: false });
        }
      }

      if (this.state.ETD !== "") {
        if (date <= this.state.ETD) {
          this.setState({ POEyETDRule: true });
        } else {
          this.setState({ POEyETDRule: false, ETDyPOERule: false });
        }
      }
    }

    if (dateStr === "ETA") {
      this.setState({
        ETA: date,
        ETARequired: false,
        PODmaxTime: moment(date).subtract({ minutes: 1 }).toDate(),
        BDDmaxTime: moment(date).subtract({ minutes: 1 }).toDate(),
        BADmaxTime: moment(date).subtract({ minutes: 1 }).toDate(),
      });

      // if (date <= this.state.today) {
      //   this.setState({ ETARequired: true });
      // }

      if (this.state.PointOfEntryDate !== "") {
        if (date <= this.state.PointOfEntryDate) {
          this.setState({ ETAyPOERule: true });
        } else {
          this.setState({ ETAyPOERule: false, POEyETARule: false });
        }
      }

      if (this.state.ETD !== "") {
        if (date <= this.state.ETD) {
          this.setState({ ETAyETDRule: true });
        } else {
          this.setState({ ETAyETDRule: false, ETDyETARule: false });
        }
      }

      if (this.state.BoundDepartureDate !== "") {
        if (date <= this.state.BoundDepartureDate) {
          this.setState({ uRule: true });
        } else {
          this.setState({ uRule: false });
        }
      }

      if (this.state.BoundestimatedTimeOfArrival !== "") {
        if (date <= this.state.verifyBoundDepartureDate) {
          this.setState({ xRule: true });
        } else {
          this.setState({ xRule: false });
        }
      }
    }

    if (dateStr === "Uetd") {
      this.setState({
        BoundDepartureDate: date,
        verifyBoundDepartureDate: false,
        BADmaxTime: moment(date).subtract({ minutes: 1 }).toDate(),
      });

      // if (date <= this.state.today) {
      //   this.setState({ verifyBoundDepartureDate: true });
      // }

      if (
        date <= this.state.ETD ||
        date <= this.state.PointOfEntryDate ||
        date >= this.state.ETA
      ) {
        this.setState({ uRule: true });
      } else {
        this.setState({ uRule: false });
      }
    }

    if (dateStr === "Xeta") {
      this.setState({
        BoundestimatedTimeOfArrival: date,
        varifyBoundestimatedTimeOfArrival: false,
      });

      // if (date <= this.state.today) {
      //   this.setState({ verifyBoundestimatedTimeOfArrival: true });
      // }

      if (
        date <= this.state.ETD ||
        date <= this.state.PointOfEntryDate ||
        date >= this.state.ETA ||
        date >= this.state.BoundDepartureDate
      ) {
        this.setState({ xRule: true });
      } else {
        this.setState({ xRule: false });
      }
    }
  };

  // START: FUNCTIONS TO VERIFY DATES RULES
  verifyExpectedTimeOfDeparture = () => {
    if (this.state.ETD === "") {
      this.setState({
        ETDRequired: true,
      });
    }

    if (this.state.ETD === null) {
      this.setState({
        ETDRequired: true,
      });
    }
  };

  verifyPointOfEntryDate = () => {
    if (this.state.PointOfEntryDate === "") {
      this.setState({
        PointOfEntryDateRequired: true,
      });
    }

    if (this.state.PointOfEntryDate === null) {
      this.setState({
        PointOfEntryDateRequired: true,
      });
    }
  };

  verifyExpectedTimeOfArrival = () => {
    if (this.state.ETA === "") {
      this.setState({
        ETARequired: true,
      });
    }

    if (this.state.ETA === null) {
      this.setState({
        ETARequired: true,
      });
    }
  };

  ETDLessThanETA = (ETDSelected, ETASelected) => {
    let ETA = ETASelected === null ? this.state.ETA : ETASelected;
    let ETD = ETDSelected === null ? this.state.ETD : ETDSelected;
    let trueStt = ETA <= ETD;

    let ShowETDError = () => {
      this.setState({ ETARequired: true, ETDRequired: false });
    };
    let HideETDError = () => {
      this.setState({ ETARequired: false, ETDRequired: false });
    };

    trueStt ? ShowETDError() : HideETDError();
  };

  POFGreatherThanETD = (POESelected, ETDSelected) => {
    let ETD = ETDSelected === null ? this.state.ETD : ETDSelected;
    let POE = POESelected === null ? this.state.PointOfEntryDate : POESelected;
    let POEGratherETD = POE >= ETD;

    let showError = () => {
      this.setState({ PointOfEntryDateRequired: true, ETDRequired: true });
    };
    let hideError = () => {
      this.setState({ PointOfEntryDateRequired: false, ETDRequired: false });
    };

    POEGratherETD ? hideError() : showError();
  };

  POFLessThanETA = (POESelected, ETASelected) => {
    let ETA = ETASelected === null ? this.state.ETA : ETASelected;
    let POE = POESelected === null ? this.state.PointOfEntryDate : POESelected;
    let ETAGreathPOE = ETA >= POE;

    let showError = () => {
      this.setState({ PointOfEntryDateRequired: true, ETARequired: true });
    };
    let hideError = () => {
      this.setState({ PointOfEntryDateRequired: false, ETARequired: false });
    };

    ETAGreathPOE ? hideError() : showError();
  };

  ETALesThanBDD = (ETASelected, BDDSelected) => {
    let ETA = ETASelected === null ? this.state.ETA : ETASelected;
    let BDD =
      BDDSelected === null ? this.state.BoundDepartureDate : BDDSelected;
    let ETALessBDD = ETA <= BDD;

    ETALessBDD
      ? this.setState({
          verifyBoundDepartureDate: true,
        })
      : this.setState({
          verifyBoundDepartureDate: false,
        });
  };

  BDDGreatherBAD = (BDDSelected, BADSelected) => {
    let BDD =
      BDDSelected === null ? this.state.BoundDepartureDate : BDDSelected;
    let BAD =
      BADSelected === null
        ? this.state.BoundestimatedTimeOfArrival
        : BADSelected;
    let BDDGreatherBAD = BDD >= BAD;

    BDDGreatherBAD
      ? this.setState({
          verifyBoundDepartureDate: true,
        })
      : this.setState({
          verifyBoundDepartureDate: false,
        });
  };

  ULessThanX = (BBDSelected, BADSelected) => {
    let U = BBDSelected === null ? this.state.BoundDepartureDate : BBDSelected;
    let X =
      BADSelected === null
        ? this.state.BoundestimatedTimeOfArrival
        : BADSelected;
    let ULessX = U <= X;
    ULessX
      ? this.setState({
          varifyBoundestimatedTimeOfArrival: true,
        })
      : this.setState({
          varifyBoundestimatedTimeOfArrival: false,
        });
  };

  XGreatherThanS = (BADSelected, ETASelected) => {
    let X =
      BADSelected === null
        ? this.state.BoundestimatedTimeOfArrival
        : BADSelected;
    let ETA = ETASelected === null ? this.state.ETA : ETASelected;
    let XGreatherS = ETA >= X;

    XGreatherS
      ? this.setState({
          varifyBoundestimatedTimeOfArrival: false,
        })
      : this.setState({
          varifyBoundestimatedTimeOfArrival: true,
        });
  };

  // START: FUNCTIONS TO APPLY DANGEROUS GOODS AND WEAPONS
  handleDangerousGoods() {
    this.setState({
      HasDangerousGoods: !this.state.HasDangerousGoods,
    });
  }

  hasClassOneDangerousGoods() {
    this.setState({
      hasClassOneDangerousGoods: !this.state.hasClassOneDangerousGoods,
    });

    if (this.state.hasClassOneDangerousGoods === false) {
      this.setState({
        quantity: "",
        unNumber: "",
        properShippingName: "",
        class: "",
        netWeight: "",
        pi: "",
        DGArrayToSubmit: [],
      });
    }
  }

  HasDangerousGoodsAuthorization() {
    this.setState({
      HasDangerousGoodsAuthorization: !this.state
        .HasDangerousGoodsAuthorization,
    });
  }

  HasWeaponsAuthorizathion() {
    this.setState({
      HasWeaponsAuthorizathion: !this.state.HasWeaponsAuthorizathion,
    });
  }

  addDangerousGood(e) {
    e.preventDefault();
    let arrayDG = this.state.DGArrayToSubmit;

    let objNew = {
      quantity: this.state.quantity,
      unNumber: this.state.unNumber,
      properShippingName: this.state.properShippingName,
      class: this.state.class,
      netWeight: this.state.netWeight,
      pi: this.state.pi,
    };

    let verifyProperShipping = /^ *$/.test(objNew.properShippingName);
    let verifyClass = /^ *$/.test(objNew.class);
    let verifyPi = /^ *$/.test(objNew.pi);

    if (objNew.quantity !== "") {
      if (objNew.unNumber !== "") {
        if (!verifyProperShipping) {
          if (!verifyClass) {
            if (objNew.netWeight !== "") {
              if (!verifyPi) {
                if (arrayDG.length >= 1) {
                  let mapDG = JSON.stringify(arrayDG.map((i) => i));
                  mapDG === objNew
                    ? this.setState({ DGalreadyExist: true })
                    : this.setState({ DGalreadyExist: false }) &&
                      arrayDG.push(objNew);
                  let newArrayDG = this.state.DGArrayToSubmit;
                  this.setState({ DGArrayToSubmit: newArrayDG });
                }

                arrayDG.push(objNew);
                let newArrayDG = this.state.DGArrayToSubmit;
                this.setState({
                  showTableDG: true,
                  DGArrayToSubmit: newArrayDG,
                  quantity: "",
                  unNumber: "",
                  properShippingName: "",
                  class: "",
                  netWeight: "",
                  pi: "",
                });
              } else {
                this.setState({ piRQ: true });
              }
            }
          } else {
            this.setState({ classRQ: true });
          }
        } else {
          this.setState({ properShippingNameRQ: true });
        }
      }
    } else {
      this.setState({
        showErrorsDG: true,
      });
    }

    if (arrayDG.length === 0) {
      this.setState({
        DGemptyArray: true,
      });
    } else {
      this.setState({
        DGemptyArray: false,
      });
    }

    if (this.state.quantity === "") {
      this.setState({ quantityRQ: true });
    }
    if (this.state.unNumber === "") {
      this.setState({ unNumberRQ: true });
    }
    if (this.state.properShippingName === "") {
      this.setState({ properShippingNameRQ: true });
    }
    if (this.state.class === "") {
      this.setState({ classRQ: true });
    }
    if (this.state.netWeight === "") {
      this.setState({ netWeightRQ: true });
    }
    if (this.state.pi === "") {
      this.setState({ piRQ: true });
    }
  }

  handleWeapons() {
    this.setState({
      HasWeapons: !this.state.HasWeapons,
    });

    if (this.state.HasWeapons === false) {
      this.setState({
        Weapons: "",
      });
    }
  }

  //FUNCTIONS TO APPLY AND VERIFY AIRSPACE
  verifyEnterAirspace = () => {
    if (
      this.state.EntersDenmark === false &&
      this.state.EntersFaroeIslands === false &&
      this.state.EntersGreenland === false
    ) {
      this.setState({
        enterSpaceRequired: true,
      });
    }
  };

  verifyAirspace() {
    let EntersDenmark = this.state.EntersDenmark;
    let EntersFaroeIslands = this.state.EntersFaroeIslands;
    let EntersGreenland = this.state.EntersGreenland;

    let allToFalse = JSON.stringify({
      EntersDenmark,
      EntersFaroeIslands,
      EntersGreenland,
    });
    let falseArray = JSON.stringify({
      EntersDenmark: false,
      EntersFaroeIslands: false,
      EntersGreenland: false,
    });
    let equals = allToFalse === falseArray;

    if (equals === true) {
      this.setState({
        enterSpaceRequired: true,
      });
    } else {
      this.setState({
        enterSpaceRequired: false,
      });
    }
  }

  handleCheckboxEntersDenmark() {
    this.setState({
      EntersDenmark: !this.state.EntersDenmark,
      enterSpaceRequired: false,
      justOneAirspace: false,
    });
    setTimeout(() => {
      this.verifyAirspace();
    }, 100);
  }

  handleCheckboxEntersFaroeIslands() {
    this.setState({
      EntersFaroeIslands: !this.state.EntersFaroeIslands,
      enterSpaceRequired: false,
      justOneAirspace: false,
    });
    setTimeout(() => {
      this.verifyAirspace();
    }, 100);
  }

  handleCheckboxEntersGreenland() {
    this.setState({
      EntersGreenland: !this.state.EntersGreenland,
      enterSpaceRequired: false,
      justOneAirspace: false,
    });
    setTimeout(() => {
      this.verifyAirspace();
    }, 100);
  }

  handleCheckboxSpecialGoods() {
    this.setState({
      SpecialGoods: !this.state.SpecialGoods,
    });
  }

  descriptionDGasRequired = () => {
    this.setState({
      quantityRQ: true,
      unNumberRQ: true,
      properShippingNameRQ: true,
      classRQ: true,
      netWeightRQ: true,
      piRQ: true,
    });
  };

  descriptionDGasExistsRequired = () => {
    this.setState({
      quantityRQ: false,
      unNumberRQ: false,
      properShippingNameRQ: false,
      classRQ: false,
      netWeightRQ: false,
      piRQ: false,
    });
  };

  validateAllFields() {
    const errors = this.state.errors;
    if (this.state.DGArrayToSubmit.length === 0) {
      this.descriptionDGasRequired();
    } else {
      this.descriptionDGasExistsRequired();
    }
    this.verifyEnterAirspace();
    this.verifyRCSarray();
    this.ETDLessThanETA();
    // this.verifyDepartureLessThanToday(this.state.ETD);
    this.ETALesThanBDD();
    this.BDDGreatherBAD();
    this.verifyBounds();
    if (this.state.PointOfEntryDate <= this.state.ETD) {
      this.setState({ PointOfEntryDateRequired: true });
      errors.push("error 1");
    }
    if (this.state.PointOfEntryDate >= this.state.ETA) {
      this.setState({ PointOfEntryDateRequired: true });
      errors.push("error 2");
    }

    if (this.state.BoundDepartureDate !== "") {
      if (this.state.BoundDepartureDate <= this.state.PointOfEntryDate) {
        this.setState({ verifyBoundDepartureDate: true });
        errors.push("error 3");
      }
      if (this.state.BoundDepartureDate >= this.state.ETA) {
        this.setState({ verifyBoundDepartureDate: true });
        errors.push("error 4");
      }
      if (
        this.state.BoundDepartureDate <= this.state.BoundestimatedTimeOfArrival
      ) {
        this.setState({ uRule: true });
        errors.push("error 6");
      }
      if (
        this.state.BoundestimatedTimeOfArrival >= this.state.BoundDepartureDate
      ) {
        this.setState({ xRule: true });
        errors.push("error 7");
      }
    }
    if (this.state.BoundestimatedTimeOfArrival !== "") {
      if (
        this.state.BoundestimatedTimeOfArrival <= this.state.PointOfEntryDate
      ) {
        this.setState({ varifyBoundestimatedTimeOfArrival: true });
        errors.push("error 5");
      }
    }
    if (this.state.ETD === "" || this.state.ETD === null) {
      this.setState({ ETDRequired: true });
      errors.push("error 8");
    }
    if (this.state.ETA === "" || this.state.ETA === null) {
      this.setState({ ETARequired: true });
      errors.push("error 9");
    }
    if (
      this.state.PointOfEntryDate === "" ||
      this.state.PointOfEntryDate === null
    ) {
      this.setState({ PointOfEntryDateRequired: true });
      errors.push("error 10");
    }
    if (this.state.RadioCallSignArrayToSubmit.length === 0) {
      this.setState({ requiredRCS: true, RCSemptyArray: true });
      errors.push("error 11");
    }
    if (this.state.HasDangerousGoods) {
      if (!this.state.hasClassOneDangerousGoods) {
        if (!this.state.HasDangerousGoodsAuthorization) {
          if (this.state.DGArrayToSubmit.length === 0) {
            this.setState({ DGemptyArray: true });
            errors.push("error DG");
          }
        }
      }
    }
    if (this.state.RegistrationNumberFirstValeu !== "") {
      this.setState({ verifyRNArray: true });
      errors.push("error 12");
    }
    if (this.state.RegistrationNumberSecondValeu !== "") {
      this.setState({ verifyRNArray: true });
      errors.push("error 13");
    }
    let UTCFormated = moment
      .utc(this.state.today)
      .format("YYYY-MM-DD HH:mm:ss");
    let ETDFormated = moment(this.state.ETD).format("YYYY-MM-DD HH:mm:ss");
    if (ETDFormated <= UTCFormated) {
      this.setState({ ETDRequired: true });
      errors.push("error 14");
    }

    if (
      this.state.DepartureAerodromeICAO === "" ||
      this.state.DepartureAerodromeICAO === null
    ) {
      errors.push("error 15");
    }

    if (
      this.state.DepartureAerodromeName === "" ||
      this.state.DepartureAerodromeName === null
    ) {
      errors.push("error 16");
    }

    if (
      this.state.DestinationAerodromeICAO === "" ||
      this.state.DestinationAerodromeICAO == null
    ) {
      errors.push("error 17");
    }

    if (
      this.state.DestinationAerodromeName === "" ||
      this.state.DestinationAerodromeName == null
    ) {
      errors.push("error 18");
    }

    return errors;
  }

  //COMMONS
  handleSubmit(e) {
    e.persist();

    let verifyErrors = this.validateAllFields();

    if (verifyErrors.length === 0) {
      this.setState({
        LoadingResponse: true,
        invalid: false,
      });

      const stateToJson = JSON.stringify({
        countryId: this.state.countryId,
        Nationality: this.state.Nationality,
        Embassy: this.state.Embassy,
        embassyAddress: this.state.embassyAddress,
        ApplicantName: this.state.ApplicantName,
        ApplicantEmail: this.state.ApplicantEmail,
        applicantTelephoneNumber: this.state.applicantTelephoneNumber,
        Purpose: this.state.Purpose,
        Summary: this.state.Summary,
        Comments: this.state.Comments,
        AircraftStatus: this.state.AircraftStatus,
        AircraftType: this.state.AircraftType,
        aircraftRegistrationNumbers: this.state.RegistrationNumberArrayToSubmit,
        RadioCallSigns: this.state.RadioCallSignArrayToSubmit,
        DepartureAerodrome: `${this.state.DepartureAerodromeICAO.toUpperCase()} - ${
          this.state.DepartureAerodromeName
        }`,
        ExpectedTimeOfDeparture: moment(this.state.ETD).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        PointOfEntry: this.state.PointOfEntry,
        PointOfEntryDate: moment(this.state.PointOfEntryDate).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        estimatedTimeOfArrival: moment(this.state.ETA).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        FlightRouteWithinDanish: this.state.FlightRouteWithinDanish,
        DestinationAerodrome: `${this.state.DestinationAerodromeICAO.toUpperCase()} - ${
          this.state.DestinationAerodromeName
        }`,
        BoundDestinationAerodrome: this.state.BoundDestinationAerodrome,
        BoundDepartureDate:
          this.state.BoundDepartureDate !== ""
            ? moment(this.state.BoundDepartureDate).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : this.state.BoundDepartureDate,
        BoundFlightRouteWithinDanish: this.state.BoundFlightRouteWithinDanish,
        BoundestimatedTimeOfArrival:
          this.state.BoundestimatedTimeOfArrival !== ""
            ? moment(this.state.BoundestimatedTimeOfArrival).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : this.state.BoundestimatedTimeOfArrival,
        PointOfExit: this.state.PointOfExit,
        HasDangerousGoods: this.state.HasDangerousGoods,
        HasDangerousGoodsAuthorization: this.state
          .HasDangerousGoodsAuthorization,
        DangerousGoods: this.state.DangerousGoods,
        hasClassOneDangerousGoods: this.state.hasClassOneDangerousGoods,
        classOneDangerousGoods: this.state.DGArrayToSubmit,
        HasWeapons: this.state.HasWeapons,
        HasWeaponsAuthorization: this.state.HasWeaponsAuthorizathion,
        Weapons: this.state.Weapons,
        EntersDenmark: this.state.EntersDenmark,
        EntersFaroeIslands: this.state.EntersFaroeIslands,
        EntersGreenland: this.state.EntersGreenland,
        FlightOperator: this.state.FlightOperator,
        ExitDate: this.state.ExitDate,

        Urgent: this.state.Urgent,
        SpecialGoods: this.state.SpecialGoods,
      });

      window
        .fetch(options.postDiplomaticClearance, {
          method: "post",
          mode: "cors",
          headers: { "Content-Type": "application/json" },
          body: stateToJson,
        })
        .then((response) => {
          if (!response.ok) throw response;
          else return response.json();
        })
        .then(() => {
          this.setState({
            LoadingResponse: false,
            sucessForm: true,
            invalid: false,
          });
        })
        .catch(() => {
          this.setState({
            sucessForm: false,
            LoadingResponse: false,
            invalid: true,
          });
        });
    } else {
      this.setState({
        invalid: true,
        errors: [],
      });
    }
  }

  handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;

    this.setState({
      [name]: value,
    });

    if (name === "RadioCallSignSecondValeu") {
      if (value !== "") {
        this.setState({
          sufixRCSRequired: false,
          sufixRCSRequiredTxt: false,
          vlExistRCS: false,
        });
      }
    }
    if (name === "RegistrationNumberSecondValeu") {
      if (value !== "") {
        this.setState({
          vlExistRN: false,
          sufixRNRequiredTxt: false,
          sufixRNRequired: false,
          prefixRNRequired: false,
        });
      }
    }

    let DGFields = [
      "quantity",
      "unNumber",
      "properShippingName",
      "class",
      "netWeight",
      "pi",
    ];

    DGFields.forEach((i) => {
      if (name === i) {
        if (value !== "") {
          this.setState({
            DGemptyArray: false,
            [i + "RQ"]: false,
          });
        }
      }
    });
  }

  keepWatchingEachMinute() {
    if (this.state.ETD !== "") {
      if (this.state.ETD <= this.state.today) {
        this.setState({ ETDRequired: true });
      }
    }
    this.setState({
      today: moment(new Date()).add({ minutes: 1 }).toDate(),
    });
  }

  componentDidMount() {
    this.setTodayEachMinute = setInterval(
      () => this.keepWatchingEachMinute(),
      60000
    );

    window
      .fetch(options.nationalities)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isLoaded: true,
          nationalitiesItems: json,
          afterLoad: true,
        });
      });

    window
      .fetch(options.boundDestinationAerodromes)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isLoaded: true,
          BoundDestinationAerodromeItems: json,
        });
      });

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    clearInterval(this.setTodayEachMinute);
  }

  render() {
    let {
      nationalitiesItems,
      RegistrationNumberArray,
      RadioCallSignArray,
      BoundDestinationAerodromeItems,
      sucessForm,
    } = this.state;

    const AircraftStatusOptions = [
      { id: 0, value: "State owned", label: "State owned" },
      { id: 1, value: "Civil aircraft", label: "Civil aircraft" },
    ];
    const PurposeOptions = [
      {
        id: 0,
        value: "Transfer of VIP and official delegations",
        label: "Transfer of VIP and official delegations",
      },
      {
        id: 1,
        value: "Transfer of diplomatic consignment",
        label: "Transfer of diplomatic consignment",
      },
      { id: 2, value: "Others", label: "Others" },
    ];
    return (
      <div className="contentForm">
        {this.state.postError && (
          <ErrorAlert errorMessage="An unexpected error happened, please, try again later" />
        )}
        {!sucessForm ? (
          <div className="formContainer">
            <b>Declaration regarding Diplomatic clearance</b>
            <p>
              By submitting the application for diplomatic clearance, I – on
              behalf of the foreign government I represent, and the persons
              mentioned in the application – declare the following:
            </p>
            <p style={{ paddingLeft: "15px" }}>
              - That I am entitled to apply for diplomatic clearance on behalf
              of the foreign state in which the aircraft is registered
            </p>
            <p style={{ paddingLeft: "15px" }}>
              - That the application only covers flight(s) considered state
              flights according to the ICAO definition
            </p>
            <p style={{ paddingLeft: "15px" }}>
              - That the aircraft covered by the application is/are not military
              registered
            </p>
            <p style={{ paddingLeft: "15px" }}>
              - That the aircraft covered by the application is/are provided
              with a valid certificate of airworthiness issued by the State in
              which the aircraft is/are registered
            </p>
            <p style={{ paddingLeft: "15px" }}>
              - The persons mentioned in the application accept the assessment
              of the application
            </p>
            <p style={{ paddingLeft: "15px" }}>
              - The flight(s) will be conducted in conformity with ICAO rules
              and regulations and applicable Danish rules and regulations.
            </p>

            <p>
              The declaration shall be submitted by person(s) empowered to
              submit applications for diplomatic clearance on behalf of the
              State in which the aircraft is/are registered.
            </p>

            <b style={{ marginTop: "15px" }}>Application Form</b>
            <p>
              Diplomatic clearance is requested for the following flight(s) in
              accordance with the Chicago Convention on International Civil
              Aviation article 3 and Danish consolidated Air Navigation Act no.
              1149 of 13 October 2017 section 156.
            </p>
            <p>
              The fields T.1, T.2, T.3 and T.4 must only be completed if the
              aircraft is/are bound for a Danish Aerodrome.
            </p>
            <p>Fields marked with * must be filled-in.</p>

            <AvForm onSubmit={this.handleSubmit} style={{ marginTop: "30px" }}>
              <div className="formGroupInput">
                <AvGroup>
                  <Label for="Nationality">
                    (A) Nation *
                    <i>(The Country in which the aircraft is registered)</i>
                  </Label>
                  {this.state.afterLoad ? (
                    <AvField
                      type="select"
                      name="Nationality"
                      id="Nationality"
                      value={this.state.Nationality}
                      onChange={this.handleNationality}
                      required="required"
                      className={this.state.afterLoad ? " " : "disabled"}
                    >
                      <option value="" disabled>
                        Select...
                      </option>
                      {nationalitiesItems.map((nation) => (
                        <option key={nation.countryId}>{nation.name}</option>
                      ))}
                    </AvField>
                  ) : (
                    <p>Loading...</p>
                  )}
                </AvGroup>
                <AvGroup>
                  <Label for="Embassy">
                    (B) Embassy (or equivalent) *
                    <i>
                      (Please note that applications for diplomatic clearance
                      shall be submitted by the country’s diplomatic
                      representation in which the aircraft is registered)
                    </i>
                  </Label>
                  <AvField
                    rows="3"
                    cols="50"
                    name="Embassy"
                    id="Embassy"
                    value={this.state.Embassy}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="embassyAddress">
                    (C) Address of Embassy (or equivalent) *
                  </Label>
                  <AvField
                    type="textarea"
                    name="embassyAddress"
                    id="embassyAddress"
                    value={this.state.embassyAddress}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="ApplicantName">
                    (D) Name of applicant *<i>(Name of Embassy employee)</i>
                  </Label>
                  <AvField
                    name="ApplicantName"
                    id="ApplicantName"
                    value={this.state.ApplicantName}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="ApplicantEmail">
                    (E) Applicant's e-mail *
                    <i>
                      (The e-mail address stated will recieve information
                      regarding the application)
                    </i>
                  </Label>
                  <AvField
                    type="email"
                    name="ApplicantEmail"
                    id="ApplicantEmail"
                    value={this.state.ApplicantEmail}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="applicantTelephoneNumber">
                    (F) Applicants telephone number *
                    <i>
                      (Ensure applicant contact information is complete and
                      current. Provide a reliable daytime number, and a
                      telephone number for use outside office hours in case of
                      urgent situations. Please do not list a number for the
                      embassy that is not answered after-hours)
                    </i>
                  </Label>
                  <AvField
                    name="applicantTelephoneNumber"
                    id="applicantTelephoneNumber"
                    value={this.state.applicantTelephoneNumber}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="Purpose">
                    (G) Purpose *
                    <i>
                      (State the official purpose of the flight. Select the
                      appropriate “Purpose” using the drop-down window. If a
                      mission is not listed, select “Other – (describe)” and
                      provide details in the “Summary” block)
                    </i>
                  </Label>
                  <AvField
                    type="select"
                    name="Purpose"
                    id="Purpose"
                    value={this.state.Purpose}
                    onChange={this.handleChange}
                    required="required"
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {PurposeOptions.map((purpose) => (
                      <option key={purpose.id} value={purpose.label}>
                        {purpose.label}
                      </option>
                    ))}
                  </AvField>
                </AvGroup>
                <AvGroup>
                  <Label for="Summary">
                    (H) Summary *
                    <i>
                      (As appropriate, include in the “Summary” block the name
                      of the conference, the name of the military exercise, or
                      any other clarifying information for the request. If
                      applicable, state the name and title/position of the
                      highest-ranking VIP on board the aircraft. In the
                      free-text “Comments” block, please add any additional
                      information that would help Naviair to better understand
                      the nature of the flight or any potential issues involved
                      )
                    </i>
                  </Label>
                  <AvField
                    type="textarea"
                    name="Summary"
                    id="Summary"
                    value={this.state.Summary}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="Comments">
                    (I) Comments
                    <i>
                      (In the free-text “Comments” section, please add any
                      additional information that would help Naviair to better
                      understand the nature of the flight or any potential
                      issues involved)
                    </i>
                  </Label>
                  <AvField
                    type="textarea"
                    name="Comments"
                    id="Comments"
                    value={this.state.Comments}
                    onChange={this.handleChange}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="AircraftStatus">
                    (J) Aircraft status *
                    <i>
                      (Enter the specific type of aircraft. It will either be a
                      “State” or “Civil” aircraft. All foreign government owned
                      non-military aircraft are considered state aircraft. On a
                      case-by-case basis, chartered civil aircraft may be
                      considered for designation as a foreign state aircraft if
                      the foreign embassy submits information regarding the
                      purpose of the flight (governmental, non-governmental in
                      nature), the owner and operator of the aircraft – e.g.,
                      owned and operated by a foreign state, or chartered by a
                      foreign state to exclusively perform a governmental
                      function. The aircraft must not be used to engage in
                      commerce, other than to exclusively transport government
                      officials or government cargo for official government
                      business in this particular case)
                    </i>
                  </Label>
                  <AvField
                    type="select"
                    name="AircraftStatus"
                    id="AircraftStatus"
                    value={this.state.AircraftStatus}
                    onChange={this.handleChange}
                    required="required"
                  >
                    <option value="" disabled>
                      Select...
                    </option>
                    {AircraftStatusOptions.map((status) => (
                      <option key={status.id} value={status.label}>
                        {status.label}
                      </option>
                    ))}
                  </AvField>
                </AvGroup>
                <AvGroup>
                  <Label for="AircraftType">
                    (K) Type of aircraft *
                    <i>(ICAO aircraft type designator should be used)</i>
                  </Label>
                  <AvField
                    type="textarea"
                    maxLength="100"
                    name="AircraftType"
                    id="AircraftType"
                    value={this.state.AircraftType}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                {RegistrationNumberArray !== undefined && (
                  <AvGroup>
                    <Label
                      for="RegistrationNumberFirstValeu"
                      className={
                        this.state.prefixRNRequired ||
                        this.state.sufixRNRequiredTxt ||
                        this.state.verifyRNArray
                          ? "text-danger"
                          : ""
                      }
                    >
                      (L) Aircraft Registration number
                      <i>
                        (Enter the ICAO civil aircraft registration code. A
                        valid entry is any alpha-numeric combination not
                        exceeding ten (10) characters in length. When entering
                        this information please do not use any blank spaces,
                        dashes, slashes, or any other special characters)
                      </i>
                    </Label>
                    <div className="col6Inputs">
                      <AvField
                        type="select"
                        name="RegistrationNumberFirstValeu"
                        id="RegistrationNumberFirstValeu"
                        className={
                          this.state.prefixRNRequired ||
                          this.state.verifyRNArray
                            ? "invalid"
                            : ""
                        }
                        value={this.state.RegistrationNumberFirstValeu}
                        onChange={this.handleRegistrationNumber}
                        required={this.state.prefixRNRequired}
                      >
                        <option value="">Select...</option>
                        {RegistrationNumberArray.map((registration) => (
                          <option
                            key={registration}
                            value={registration.toUpperCase()}
                            style={{ textTransform: "uppercase" }}
                          >
                            {registration.toUpperCase()}
                          </option>
                        ))}
                      </AvField>
                      <AvField
                        maxLength={
                          this.state.AllowNumberCaractersRegistrationNumber
                        }
                        style={{ textTransform: "uppercase" }}
                        name="RegistrationNumberSecondValeu"
                        id="RegistrationNumberSecondValeu"
                        className={
                          this.state.sufixRNRequired ||
                          this.state.sufixRNRequiredTxt ||
                          this.state.verifyRNArray
                            ? "invalid"
                            : ""
                        }
                        value={this.state.RegistrationNumberSecondValeu}
                        onChange={this.handleChange}
                        required={this.state.prefixRNRequired}
                      />
                      <button
                        onClick={this.addRegistrationNumber}
                        className="addItem"
                      >
                        Add
                      </button>
                      {this.state.vlExistRN && (
                        <p
                          className="text-danger"
                          style={{ marginTop: "-10px" }}
                        >
                          Value already exist
                        </p>
                      )}
                      {this.state.prefixRNRequiredTxt && (
                        <p className="text-danger prefix">
                          This field is invalid
                        </p>
                      )}
                      {this.state.sufixRNRequiredTxt && (
                        <p className="text-danger sufix">
                          This field is invalid
                        </p>
                      )}
                    </div>

                    <div
                      className={
                        this.state.showTableRN ? "tableRN showTable" : "tableRN"
                      }
                    >
                      <div className="titleTable">
                        Aircraft registration number
                      </div>
                      {this.state.RegistrationNumberArrayToSubmit.length > 0 &&
                        this.state.RegistrationNumberArrayToSubmit.map(
                          (vl, i) => (
                            <div className="rowTable" key={i}>
                              <div style={{ textTransform: "uppercase" }}>
                                {vl}
                              </div>
                              <div
                                className="removeItem"
                                onClick={() => this.removeItemRN(i)}
                              >
                                Remove
                              </div>
                            </div>
                          )
                        )}
                    </div>
                    {this.state.verifyRNArray && (
                      <p className="text-danger" style={{ marginTop: "-10px" }}>
                        This field is invalid
                      </p>
                    )}
                  </AvGroup>
                )}
                {RadioCallSignArray !== undefined && (
                  <AvGroup>
                    <Label
                      for="RadioCallSignFirstValeu"
                      className={
                        this.state.sufixRCSRequired ||
                        this.state.prefixRCSRequired ||
                        this.state.RCSemptyArray
                          ? "text-danger"
                          : " "
                      }
                    >
                      (M) Radio call sign *
                      <i>
                        (Enter the aircraft Call Sign that will be filed on the
                        International Civil Aviation Organization (ICAO) flight
                        plan. Valid state aircraft call signs for diplomatic
                        flights are any alpha-numeric combination not to exceed
                        seven (7) characters, usually beginning with an
                        alphabetic character. If the state aircraft operator has
                        an approved ICAO three-letter designator, please use
                        this designator as the first three letters of the call
                        sign. When entering this information please do not use
                        any blank spaces, dashes, slashes, or any other special
                        characters)
                      </i>
                    </Label>
                    <div className="col6Inputs">
                      <AvField
                        type="select"
                        name="RadioCallSignFirstValeu"
                        id="RadioCallSignFirstValeu"
                        value={this.state.RadioCallSignFirstValeu.toUpperCase()}
                        className={
                          this.state.prefixRCSRequired ||
                          this.state.RCSemptyArray
                            ? " invalid"
                            : " "
                        }
                        onChange={this.handleRadioCallSign}
                        required={this.state.prefixRCSRequired}
                      >
                        <option value="">Select...</option>
                        {RadioCallSignArray.map((registration) => (
                          <option
                            key={registration}
                            value={registration.toUpperCase()}
                            style={{ textTransform: "uppercase" }}
                          >
                            {registration.toUpperCase()}
                          </option>
                        ))}
                      </AvField>
                      <AvField
                        maxLength={this.state.AllowNumberCaractersRadioCallSign}
                        name="RadioCallSignSecondValeu"
                        id="RadioCallSignSecondValeu"
                        style={{ textTransform: "uppercase" }}
                        value={this.state.RadioCallSignSecondValeu}
                        className={
                          this.state.sufixRCSRequired ||
                          this.state.RCSemptyArray
                            ? "invalid"
                            : " "
                        }
                        onChange={this.handleChange}
                        required={this.state.sufixRCSRequired}
                      />
                      <button
                        onClick={this.addRadioCallSign}
                        className="addItem"
                      >
                        Add
                      </button>
                      {this.state.vlExistRCS && (
                        <p className="text-danger prefix">
                          Value already exist
                        </p>
                      )}
                      {this.state.prefixRCSRequiredTxt && (
                        <p className="text-danger prefix">
                          This field is invalid
                        </p>
                      )}
                      {this.state.RCSemptyArray && (
                        <p className="text-danger prefix">
                          This field is invalid
                        </p>
                      )}
                    </div>

                    <div
                      className={
                        this.state.showTableRCS
                          ? "tableRCS showTable"
                          : "tableRCS"
                      }
                    >
                      <div className="titleTable">Radio call sign</div>
                      {this.state.RadioCallSignArrayToSubmit.length > 0 &&
                        this.state.RadioCallSignArrayToSubmit.map((vl, i) => (
                          <div className="rowTable" key={i}>
                            <div style={{ textTransform: "uppercase" }}>
                              {vl}
                            </div>
                            <div
                              className="removeItem"
                              onClick={() => this.removeItemRCS(i)}
                            >
                              Remove
                            </div>
                          </div>
                        ))}
                    </div>
                  </AvGroup>
                )}
                <AvGroup>
                  <Label for="DepartureAerodromeICAO">
                    (N) Departure aerodrome *
                    <i>
                      (Use the four (4) letter ICAO aerodrome designator and the
                      name of the aerodrome)
                    </i>
                  </Label>
                  <div className="col2Inputs">
                    <AvField
                      maxLength="4"
                      type="text"
                      name="DepartureAerodromeICAO"
                      id="DepartureAerodromeICAO"
                      value={this.state.DepartureAerodromeICAO}
                      onChange={this.handleChange}
                      required="required"
                      style={{ textTransform: "uppercase" }}
                    />
                    <AvField
                      name="DepartureAerodromeName"
                      id="DepartureAerodromeName"
                      value={this.state.DepartureAerodromeName}
                      onChange={this.handleChange}
                      required="required"
                    />
                  </div>
                </AvGroup>
                <div style={{ margin: "0 0 20px" }}>
                  <label
                    onClick={(e) => e.preventDefault()}
                    className={
                      this.state.ETDRequired ||
                      this.state.ETDInThePast ||
                      this.state.ETDyPOERule ||
                      this.state.ETDyETARule
                        ? "text-danger"
                        : " "
                    }
                  >
                    (O) ETD *<i>(Use UTC Scale)</i>
                    <DatePicker
                      selected={this.state.ETD}
                      openToDate={new Date()}
                      onChange={(date) => this.changeAllDates("ETD", date)}
                      onBlur={this.verifyExpectedTimeOfDeparture}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy HH:mm"
                      className={
                        this.state.ETDRequired ||
                        this.state.ETDInThePast ||
                        this.state.ETDyPOERule ||
                        this.state.ETDyETARule
                          ? "inputText invalid"
                          : "inputText"
                      }
                    />
                  </label>
                  {this.state.ETDRequired && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      This field is invalid
                    </p>
                  )}
                  {this.state.ETDInThePast && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      The ETD cannot be in the past
                    </p>
                  )}
                  {this.state.ETDyPOERule && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      (O) ETD must be before (P.2) Point of entry
                    </p>
                  )}
                  {this.state.ETDyETARule && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      (O) ETD must be before (S) ETA
                    </p>
                  )}
                </div>
                <AvGroup>
                  <Label for="PointOfEntry">
                    (P.1) Point of entry into Danish territory or FIR *
                    <i>
                      (For transits or overflights only, ensure the entry and
                      exit points and times are provided by the aircrew or
                      mission planner, and put all times in UTC scale)
                    </i>
                  </Label>
                  <AvField
                    name="PointOfEntry"
                    id="PointOfEntry"
                    value={this.state.PointOfEntry}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <div style={{ margin: "0 0 20px" }}>
                  <label
                    onClick={(e) => e.preventDefault()}
                    className={
                      this.state.PointOfEntryDateRequired ||
                      this.state.POEyETARule ||
                      this.state.POEyETDRule
                        ? "text-danger"
                        : " "
                    }
                  >
                    (P.2) Point of entry date *<i>(Use UTC Scale)</i>
                    <DatePicker
                      selected={this.state.PointOfEntryDate}
                      onChange={(date) => this.changeAllDates("PoE", date)}
                      onBlur={this.verifyPointOfEntryDate}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy HH:mm"
                      className={
                        this.state.PointOfEntryDateRequired ||
                        this.state.POEyETARule ||
                        this.state.POEyETDRule
                          ? "inputText invalid"
                          : "inputText"
                      }
                    />
                  </label>
                  {this.state.PointOfEntryDateRequired && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      This field is invalid
                    </p>
                  )}
                  {this.state.POEyETDRule && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      (P.2) Point of entry date must be after (O) ETD
                    </p>
                  )}
                  {this.state.POEyETARule && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      (P.2) Point of entry date must be before (S) ETA
                    </p>
                  )}
                </div>
                <AvGroup>
                  <Label for="FlightRouteWithinDanish">
                    (Q) Route of flight within within Danish territory *
                  </Label>
                  <AvField
                    name="FlightRouteWithinDanish"
                    id="FlightRouteWithinDanish"
                    value={this.state.FlightRouteWithinDanish}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="DestinationAerodromeICAO">
                    (R) Destination aerodrome *
                    <i>
                      (Use the four (4) letter ICAO aerodrome designator and the
                      name of the aerodrome)
                    </i>
                  </Label>
                  <div className="col2Inputs">
                    <AvField
                      maxLength="4"
                      name="DestinationAerodromeICAO"
                      id="DestinationAerodromeICAO"
                      value={this.state.DestinationAerodromeICAO}
                      onChange={this.handleChange}
                      required="required"
                      style={{ textTransform: "uppercase" }}
                    />
                    <AvField
                      name="DestinationAerodromeName"
                      id="DestinationAerodromeName"
                      value={this.state.DestinationAerodromeName}
                      onChange={this.handleChange}
                      required="required"
                    />
                  </div>
                </AvGroup>
                <div style={{ margin: "0 0 19px" }}>
                  <label
                    className={
                      this.state.ETARequired ||
                      this.state.ETAyETDRule ||
                      this.state.ETAyPOERule
                        ? "text-danger"
                        : " "
                    }
                  >
                    (S) ETA *<i>(Use UTC Scale)</i>
                    <DatePicker
                      selected={this.state.ETA}
                      onChange={(date) => this.changeAllDates("ETA", date)}
                      onBlur={this.verifyExpectedTimeOfArrival}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={1}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy HH:mm"
                      className={
                        this.state.ETARequired ||
                        this.state.ETAyETDRule ||
                        this.state.ETAyPOERule
                          ? "inputText invalid"
                          : "inputText"
                      }
                    />
                  </label>
                  {this.state.ETARequired && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      This field is invalid
                    </p>
                  )}
                  {this.state.ETAyETDRule && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      (S) ETA must be after (O) ETD
                    </p>
                  )}
                  {this.state.ETAyPOERule && (
                    <p className="text-danger" style={{ margin: "0" }}>
                      (S) ETA must be after (P.2) Point of entry date
                    </p>
                  )}
                </div>
                <AvGroup>
                  <Label for="BoundDestinationAerodrome">
                    (T.1) Destination Aerodrome
                    <i>
                      (Only to be filled in if the aircraft is bound for a
                      Danish, Greenlandic or Faroe Islands Aerodrome. Use the
                      four (4) letter ICAO aerodrome designator)
                    </i>
                  </Label>
                  {this.state.isLoaded ? (
                    <AvField
                      type="select"
                      name="BoundDestinationAerodrome"
                      id="BoundDestinationAerodrome"
                      value={this.state.BoundDestinationAerodrome}
                      onChange={this.handleBoundDestinationAerodrome}
                    >
                      <option value="">Select...</option>
                      {BoundDestinationAerodromeItems.map((aerodrome) => (
                        <option key={aerodrome.name} value={aerodrome.name}>
                          {aerodrome.name}
                        </option>
                      ))}
                    </AvField>
                  ) : (
                    <p>Loading...</p>
                  )}
                </AvGroup>
                {this.state.ifAircraftIsBound && (
                  <div>
                    <div style={{ margin: "0 0 25px" }}>
                      <label
                        className={
                          this.state.verifyBoundDepartureDate ||
                          this.state.uRule
                            ? "text-danger"
                            : ""
                        }
                      >
                        (T.2) Date and time (Use UTC Scale) of Departure from a
                        Danish aerodrome
                        <i>
                          (Only to be filled in if the aircraft is bound for a
                          Danish Aerodrome)
                        </i>
                        <DatePicker
                          selected={this.state.BoundDepartureDate}
                          onChange={(date) => this.changeAllDates("Uetd", date)}
                          onBlur={this.verifyBoundDepartureDateEmpty}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy HH:mm"
                          className={
                            this.state.verifyBoundDepartureDate ||
                            this.state.uRule
                              ? "inputText invalid"
                              : "inputText"
                          }
                        />
                      </label>
                      {this.state.verifyBoundDepartureDate && (
                        <p className="text-danger" style={{ margin: "0" }}>
                          This field is invalid
                        </p>
                      )}
                      {this.state.uRule && (
                        <p className="text-danger" style={{ margin: "0" }}>
                          (T.2) ETD must be after (P.2) POE and before (S) ETA
                        </p>
                      )}
                    </div>

                    <div style={{ margin: "0 0 25px" }}>
                      <label
                        className={
                          this.state.verifyBoundFlightRouteWithinDanish
                            ? "text-danger"
                            : ""
                        }
                      >
                        (T.3) Route of flight within Danish territory
                        <i>
                          (Only to be filled in if the aircraft is bound for a
                          Danish Aerodrome) *
                        </i>
                        <input
                          type="text"
                          name="BoundFlightRouteWithinDanish"
                          className={
                            this.state.verifyBoundFlightRouteWithinDanish
                              ? "inputText invalid"
                              : "inputText"
                          }
                          value={this.state.BoundFlightRouteWithinDanish}
                          onBlur={() => {
                            this.setState({
                              verifyBoundFlightRouteWithinDanish: false,
                            });
                          }}
                          onChange={this.handleChange}
                        />
                      </label>
                      {this.state.verifyBoundFlightRouteWithinDanish && (
                        <p className="text-danger">This field is invalid</p>
                      )}
                    </div>

                    <div style={{ margin: "0 0 25px" }}>
                      <label
                        className={
                          this.state.varifyBoundestimatedTimeOfArrival ||
                          this.state.xRule
                            ? "text-danger"
                            : ""
                        }
                      >
                        (T.4) ETA (Use UTC Scale)
                        <i>
                          (Only to be filled in if the aircraft is bound for a
                          Danish Aerodrome)
                        </i>
                        <DatePicker
                          selected={this.state.BoundestimatedTimeOfArrival}
                          onChange={(date) => this.changeAllDates("Xeta", date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          timeCaption="time"
                          onBlur={this.verifyBoundestimatedTimeOfArrival}
                          dateFormat="MMMM d, yyyy HH:mm"
                          className={
                            this.state.varifyBoundestimatedTimeOfArrival ||
                            this.state.xRule
                              ? "inputText invalid"
                              : "inputText"
                          }
                        />
                      </label>
                      {this.state.varifyBoundestimatedTimeOfArrival && (
                        <p className="text-danger">This field is invalid</p>
                      )}
                      {this.state.xRule && (
                        <p className="text-danger">
                          (T.4) ETA must be before (T.2) ETD and (S) ETA and
                          after (P.2) Point of entry date.
                        </p>
                      )}
                    </div>
                  </div>
                )}
                <AvGroup>
                  <Label for="PointOfExit">
                    (U) Point of exit from Danish territory or FIR *
                  </Label>
                  <AvField
                    name="PointOfExit"
                    id="PointOfExit"
                    value={this.state.PointOfExit}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
                <div className="formGroupInput formGroupInput--checkBox">
                  <input
                    type="checkbox"
                    id="HasDangerousGoods"
                    name="HasDangerousGoods"
                    checked={this.state.HasDangerousGoods}
                    onChange={this.handleDangerousGoods}
                    className="css-checkbox"
                  />
                  <label htmlFor="HasDangerousGoods" className="css-label">
                    (V) Dangerous goods
                  </label>
                  {this.state.HasDangerousGoods && (
                    <div className="checkboxIndent">
                      <input
                        type="checkbox"
                        id="hasClassOneDangerousGoods"
                        name="hasClassOneDangerousGoods"
                        checked={this.state.hasClassOneDangerousGoods}
                        onChange={this.hasClassOneDangerousGoods}
                        className="css-checkbox"
                      />
                      <label
                        htmlFor="hasClassOneDangerousGoods"
                        className="css-label classOne"
                      >
                        Is the dangerous goods in Class 1.4 S?
                      </label>
                      <p style={{ margin: "0 0 0 20px" }}>
                        <i>
                          (No permission for dangerous goods from the Danish
                          Transport, Construction and Housing Authority is
                          necessary, however the transport must comply with
                          Danish Executive Order No. 763 of 11 July 2008)
                        </i>
                      </p>
                      {!this.state.hasClassOneDangerousGoods && (
                        <>
                          <input
                            type="checkbox"
                            id="HasDangerousGoodsAuthorization"
                            name="HasDangerousGoodsAuthorization"
                            checked={this.state.HasDangerousGoodsAuthorization}
                            onChange={this.HasDangerousGoodsAuthorization}
                            className="css-checkbox"
                          />
                          <label
                            htmlFor="HasDangerousGoodsAuthorization"
                            className="css-label hasDGAut"
                          >
                            Have you submitted an application directly to the
                            Danish Transport, Construction and Housing
                            Authority?
                          </label>
                          <p style={{ margin: "0 0 0 20px" }}>
                            <i style={{ display: "block" }}>
                              If not, describe the type of cargo the aircraft is
                              carrying.
                            </i>
                            <i>
                              (If the cargo is hazardous, please complete
                              details on the type of HAZMAT. Note: Please state
                              the Net Explosive Weight (NEW) of the item, not
                              the actual physical weight of the item(s). Use
                              IATA classifications system. Naviair will forward
                              the information to the Danish Transport,
                              Construction and Housing Authority)
                            </i>
                            <i
                              style={{
                                margin: "0px 0 5px",
                                display: "block",
                              }}
                            >
                              Numbers, UN Number, Proper shipping name, Class or
                              Div. Class, Net weight (kg), PI
                            </i>
                          </p>
                          <div>
                            {!this.state.HasDangerousGoodsAuthorization && (
                              <div className="dgForm ">
                                <AvGroup
                                  className={
                                    this.state.quantityRQ ? "text-danger" : ""
                                  }
                                >
                                  <Label for="quantity">Quantity *</Label>
                                  <AvField
                                    className={
                                      this.state.quantityRQ ? "invalid" : ""
                                    }
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    min="0"
                                    value={this.state.quantity}
                                    onChange={this.handleChange}
                                    required={this.state.quantityRQ}
                                  />
                                </AvGroup>

                                <AvGroup
                                  className={
                                    this.state.unNumberRQ ? "text-danger" : ""
                                  }
                                >
                                  <Label for="unNumber">UN Number *</Label>
                                  <AvField
                                    className={
                                      this.state.unNumberRQ ? "invalid" : ""
                                    }
                                    type="number"
                                    name="unNumber"
                                    id="unNumber"
                                    min="0"
                                    value={this.state.unNumber}
                                    onChange={this.handleChange}
                                    required={this.state.unNumberRQ}
                                  />
                                </AvGroup>

                                <AvGroup
                                  className={
                                    this.state.properShippingNameRQ
                                      ? "text-danger"
                                      : ""
                                  }
                                >
                                  <Label for="properShippingName">
                                    Proper shipping name *
                                  </Label>
                                  <AvField
                                    className={
                                      this.state.properShippingNameRQ
                                        ? "invalid"
                                        : ""
                                    }
                                    name="properShippingName"
                                    id="properShippingName"
                                    value={this.state.properShippingName}
                                    onChange={this.handleChange}
                                    required={this.state.properShippingNameRQ}
                                  />
                                </AvGroup>

                                <AvGroup
                                  className={
                                    this.state.classRQ ? "text-danger" : ""
                                  }
                                >
                                  <Label for="class">Class *</Label>
                                  <AvField
                                    className={
                                      this.state.classRQ ? "invalid" : ""
                                    }
                                    name="class"
                                    id="class"
                                    value={this.state.class}
                                    onChange={this.handleChange}
                                    required={this.state.classRQ}
                                  />
                                </AvGroup>

                                <AvGroup
                                  className={
                                    this.state.netWeightRQ ? "text-danger" : ""
                                  }
                                >
                                  <Label for="netWeight">
                                    Net weight (kg) *
                                  </Label>
                                  <AvField
                                    className={
                                      this.state.netWeightRQ ? "invalid" : ""
                                    }
                                    type="number"
                                    name="netWeight"
                                    id="netWeight"
                                    min="0"
                                    value={this.state.netWeight}
                                    onChange={this.handleChange}
                                    required={this.state.netWeightRQ}
                                  />
                                </AvGroup>

                                <AvGroup
                                  className={
                                    this.state.piRQ ? "text-danger" : ""
                                  }
                                >
                                  <Label for="pi">PI *</Label>
                                  <i
                                      style={{
                                        margin: "0px 0 5px",
                                        display: "block",
                                      }}
                                  >
                                    (Packing Instruction. Reference to correct packaging of goods according to UN number)
                                  </i>
                                  <AvField
                                    className={this.state.piRQ ? "invalid" : ""}
                                    name="pi"
                                    id="pi"
                                    value={this.state.pi}
                                    onChange={this.handleChange}
                                    required={this.state.piRQ}
                                  />
                                </AvGroup>
                                <button
                                  type="submit"
                                  onClick={this.addDangerousGood}
                                  className="addItem"
                                >
                                  Add
                                </button>
                                {this.state.DGalreadyExist && (
                                  <p>Values above already exist</p>
                                )}
                                {this.state.DGemptyArray && (
                                  <p
                                    className="text-danger"
                                    style={{ margin: "0" }}
                                  >
                                    This field is invalid.
                                  </p>
                                )}

                                <div
                                  className={
                                    this.state.showTableDG
                                      ? "tableDG showTable"
                                      : "tableDG"
                                  }
                                >
                                  <div
                                    className="titleTable"
                                    style={{ margin: "15px 0 0" }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "13.5px",
                                        padding: "5px 0",
                                        display: "flex",
                                      }}
                                    >
                                      Dangerous goods
                                    </span>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                        borderTop: "1px solid",
                                        textAlign: "center",
                                        fontSize: "11.5px",
                                      }}
                                    >
                                      <span style={{ flex: "0 0 14%" }}>
                                        Quantity
                                      </span>
                                      <span style={{ flex: "0 0 14%" }}>
                                        UN Number
                                      </span>
                                      <span style={{ flex: "0 0 14%" }}>
                                        Shipping Name
                                      </span>
                                      <span style={{ flex: "0 0 14%" }}>
                                        Class
                                      </span>
                                      <span style={{ flex: "0 0 14%" }}>
                                        Net Weight
                                      </span>
                                      <span style={{ flex: "0 0 14%" }}>
                                        PI
                                      </span>
                                      <span style={{ flex: "0 0 14%" }}>
                                        Action
                                      </span>
                                    </div>
                                  </div>
                                  {this.state.DGArrayToSubmit.map((vl, i) => (
                                    <div
                                      key={i}
                                      className="rowTable"
                                      style={{ textAlign: "center" }}
                                    >
                                      <p style={{ flex: "0 0 14%" }}>
                                        {vl.quantity}
                                      </p>
                                      <p style={{ flex: "0 0 14%" }}>
                                        {vl.unNumber}
                                      </p>
                                      <p style={{ flex: "0 0 14%" }}>
                                        {vl.properShippingName}
                                      </p>
                                      <p style={{ flex: "0 0 14%" }}>
                                        {vl.class}
                                      </p>
                                      <p style={{ flex: "0 0 14%" }}>
                                        {vl.netWeight}
                                      </p>
                                      <p style={{ flex: "0 0 14%" }}>{vl.pi}</p>
                                      <div
                                        className="removeItem"
                                        onClick={() => this.removeItemDGItem(i)}
                                        style={{ flex: "0 0 14%" }}
                                      >
                                        Remove
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {this.state.DangerousGoodsRequired && (
                        <p
                          className="text-danger"
                          style={{ margin: "5px 0 0" }}
                        >
                          Please describe the dangerous cargo
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="formGroupInput formGroupInput--checkBox">
                  <input
                    type="checkbox"
                    id="HasWeapons"
                    name="HasWeapons"
                    checked={this.state.HasWeapons}
                    onChange={this.handleWeapons}
                    className="css-checkbox"
                  />
                  <label htmlFor="HasWeapons" className="css-label">
                    (W) Weapons
                  </label>
                  {this.state.HasWeapons && (
                    <div className="checkboxIndent">
                      <input
                        type="checkbox"
                        id="HasWeaponsAuthorizathion"
                        name="HasWeaponsAuthorizathion"
                        checked={this.state.HasWeaponsAuthorizathion}
                        onChange={this.HasWeaponsAuthorizathion}
                        className="css-checkbox"
                      />
                      <label
                        htmlFor="HasWeaponsAuthorizathion"
                        className="css-label hasWeapons"
                      >
                        Have you submitted an application directly to the Danish
                        Transport, Construction and Housing Authority?
                      </label>

                      {!this.state.HasWeaponsAuthorizathion && (
                        <div style={{ margin: "0 0 0 20px" }}>
                          <AvGroup>
                            <Label for="Weapons" style={{ margin: "0" }}>
                              <i>Specify weapons and explosives</i>
                              <i>
                                (Describe all weapons transported on the flight
                                by type, calibre and manufacture of the weapons.
                                This section is mainly for weapons transported
                                by individuals who will deplane upon arrival,
                                and not for bulk weapons being transported as
                                cargo, although they too are required to be
                                identified as such. Naviair will forward the
                                information to the Danish Transport,
                                Construction and Housing Authority)
                              </i>
                            </Label>
                            <AvField
                              type="textarea"
                              rows="3"
                              cols="50"
                              name="Weapons"
                              id="Weapons"
                              value={this.state.Weapons}
                              onChange={this.handleChange}
                              required
                            />
                          </AvGroup>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={
                    this.state.enterSpaceRequired
                      ? "formGroupInput formGroupInput--checkBox text-danger"
                      : "formGroupInput formGroupInput--checkBox"
                  }
                  style={{ margin: "0 0 5px" }}
                >
                  <input
                    type="checkbox"
                    id="EntersDenmark"
                    name="EntersDenmark"
                    checked={this.state.EntersDenmark}
                    onChange={this.handleCheckboxEntersDenmark}
                    className="css-checkbox"
                  />
                  <label htmlFor="EntersDenmark" className="css-label">
                    (X.1) Specify if the flight enters the airspace of Denmark *
                  </label>
                </div>
                <div
                  className={
                    this.state.enterSpaceRequired
                      ? "formGroupInput formGroupInput--checkBox text-danger"
                      : "formGroupInput formGroupInput--checkBox"
                  }
                >
                  <input
                    type="checkbox"
                    id="EntersFaroeIslands"
                    name="EntersFaroeIslands"
                    checked={this.state.EntersFaroeIslands}
                    onChange={this.handleCheckboxEntersFaroeIslands}
                    className="css-checkbox"
                  />
                  <label htmlFor="EntersFaroeIslands" className="css-label">
                    (X.2) Specify if the flight enters the airspace of the Faroe
                    Islands *
                  </label>
                </div>
                <div
                  className={
                    this.state.enterSpaceRequired
                      ? "formGroupInput formGroupInput--checkBox text-danger"
                      : "formGroupInput formGroupInput--checkBox"
                  }
                  style={{ margin: "0 0 20px" }}
                >
                  <input
                    type="checkbox"
                    id="EntersGreenland"
                    name="EntersGreenland"
                    checked={this.state.EntersGreenland}
                    onChange={this.handleCheckboxEntersGreenland}
                    className="css-checkbox"
                  />
                  <label htmlFor="EntersGreenland" className="css-label">
                    (X.3) Specify if the flight enters the airspace of Greenland
                    *
                  </label>
                  {this.state.enterSpaceRequired && (
                    <p className="text-danger dangertAirspace">
                      Select at least one (X.1) (X.2) (X.3)
                    </p>
                  )}
                </div>
                <AvGroup>
                  <Label for="FlightOperator">
                    (Y) Operator of the flight * <i>(Name and address of airline / air carrier)</i>
                  </Label>
                  <AvField
                    name="FlightOperator"
                    id="FlightOperator"
                    value={this.state.FlightOperator}
                    onChange={this.handleChange}
                    required
                  />
                </AvGroup>
              </div>
              <input type="submit" value="Send" />
              {this.state.LoadingResponse && <Loading />}
              {this.state.invalid && (
                <div style={{ margin: "5px 0 0" }}>
                  <p className="text-danger">
                    Form above contains errors, please correct them and try
                    again.
                  </p>
                </div>
              )}
            </AvForm>
          </div>
        ) : (
          <div className="resultContainer">
            <Sucess />
          </div>
        )}
      </div>
    );
  }
}

export default ValidationForm;

ValidationForm.propTypes = {
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
};
