import React, { Component } from 'react'
import Header from '../layout/Header'

import Footer from '../layout/Footer'
import ValidationForm from '../components/ValidationForm'

export class FormDiplomaticClearance extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="contentContainer contentContainer--Form">
                    <ValidationForm/>
                    <a href="/diplomatic-clearance.1826" onClick={() => window.print()} className="printIcon">printIcon</a>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default FormDiplomaticClearance
